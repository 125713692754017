import {Injectable} from '@angular/core';
import {SignalService} from "@core/services/signal.service";
import {RoleReference} from "@core/models/role.model";

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  constructor(private signalService: SignalService) {
  }

  saveAuthorities(authorities: { authority: string }[]): void {
    this.signalService.authorities.set(authorities.map(el => el.authority))
  }

  saveRole(role: RoleReference): void {
    this.signalService.role.set(role)
  }

  isPermitted(permission: string): boolean {
    if (permission.includes('ACCESS_ROLES') || permission.includes('ACCESS_BRANCHES')) {
      return this.signalService.role()?.name === 'ROLE_SUPERUSER'
    }
    return this.signalService.authorities().includes(permission);
  }
}
